import React from "react";
import Base from "../components/Base";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import Img from "gatsby-image"

const AboutUS = () => {
    const images = useStaticQuery(graphql`
        query IndexQuery {
            city: file(relativePath: { eq: "accreditation/city-and-guilds.jpg" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            },
            safety: file(relativePath: { eq: "accreditation/electricial-safety.jpg" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            },
        }
    `);

    return (
            <Base title={'Domestic and Commerical electrician in Harrow, Watford, Middlesex and London'}>
                <h2 className={'colouredTitle'}>About Us</h2>   
                <hr/>
                <p style={{ textAlign: 'left' }}>
                    With over 10 years experience specialising in domestic and commercial installations we have worked hard to build a client base from reccomendations and repeat business. 
                </p>
                <p style={{ textAlign: 'left' }}>
                    Our priority has always been customer satisfaction. We pride ourselves on being reliable, fast and affordable from the smallest of jobs to the biggest ensuring that each project meets our high standards and satisfaction is always guaranteed. 
                </p>
                <p style={{ textAlign: 'left' }}>
                    When using our services, you can count on us for the results you want and need. All work carried out complies with the latest 18th edition wiring regulations, fully insured and guaranteed.                        
                </p>
                <p>
                    To see the services we offer and more details visit the <b><Link className={'specialLink'} to='/services'>services</Link></b> page.
                </p>

                <h3 className={'colouredTitle'}>Accreditation</h3>
                <hr/>
                <div id={'accreditationHolder'}>
                    <div>
                        <Img className={'accreditationImg'} fluid={images.city.childImageSharp.fluid} alt="City and Guilds logo"/>
                    </div>
                    <div>
                        <Img className={'accreditationImg'} fluid={images.safety.childImageSharp.fluid} alt="Electricial safety council logo"/>
                    </div>                        
                </div>
            </Base>
        );
}
export default AboutUS;
